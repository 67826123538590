/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

    init_BG: function() {
      $(window).on('load', function() {
        $('.status').fadeOut();
        $('.init').delay(350).fadeOut('slow');
        $('body').delay(350).css({'overflow': 'visible'});
      });
    },

    enable_sticky: function() {
      if ($(window).width() > 992) {
        $(window).scroll(function(){
           if ($(this).scrollTop() > 200) {
              $('#navbar').addClass("fixed-top");
              // add padding top to show content behind navbar
              //$('body').css('padding-top', $('.navbar').outerHeight() + 'px');
            }else{
              $('#navbar').removeClass("fixed-top");
               // remove padding top from body
              $('body').css('padding-top', '0');
            }
        });
      } // end if
    },

    enable_pageAnimation: function() {
      $('.animsition').animsition({
        inClass: 'fade-in-down-sm',
        outClass: 'fade-out-up-sm',
        inDuration: 1200,
        outDuration: 600,
        linkElement: '.animsition-link',
        loading: true,
        loadingParentElement: 'body',
        loadingClass: 'animsition-loading',
        loadingInner: '',
        timeout: false,
        timeoutCountdown: 5000,
        onLoadEvent: true,
        browser: [ 'animation-duration', '-webkit-animation-duration'],
        overlay: false,
        overlayClass: 'animsition-overlay-slide',
        overlayParentElement: 'body',
        transition: function(url){ window.location.href = url; }
      });
    },

    enable_carousel: function() {
      $('.carousel').carousel();
    },

    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    // Fullwidth
    'page_template_fullwidth_page_php': {
      init: function() {
        Sage.init_BG();
        Sage.enable_pageAnimation();
        Sage.enable_sticky();
        Sage.enable_carousel();
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    // Home page
    'is_front': {
      init: function() {
        Sage.init_BG();
        Sage.enable_pageAnimation();
        Sage.enable_sticky();
        Sage.enable_carousel();
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
